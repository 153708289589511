<style scoped>
  .text-danger {
    color: red !important;
  }
</style>
<template>
    <!-- HORIZONTAL LAYOUT -->
    <div class="vx-row">
        <div class="vx-col sm:w-1/2 w-full mb-base">
            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/6 w-full">
                    <span>Allow Partial</span>
                </div>
                <div class="vx-col vx-row sm:w-5/6 w-full">
                    <div class="vx-col sm:w-1/6">
                        <input type="radio" value="1" v-model="salesOrder.AllowPartial">
                        <label> Yes</label>
                    </div>
                    <div class="vx-col sm:w-1/6">
                        <input type="radio" value="0" v-model="salesOrder.AllowPartial">
                        <label> No</label>
                    </div>
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/6 w-full">
                    <span>SO Code</span>
                </div>
                <div class="vx-col sm:w-5/6 w-full">
                    <vs-input class="w-full" v-model="salesOrder.Code" disabled/>
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/6 w-full">
                    <span>Ref. PO Code</span>
                </div>
                <div class="vx-col sm:w-5/6 w-full">
                    <vs-input class="w-full" v-model="salesOrder.PoReferenceCode"/>
                </div>
            </div>
            <!-- <fieldReservationCode :reservationCode="salesOrder.ReservationCode" v-on:browseReservationCode="browseReservationCode"></fieldReservationCode> -->
            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/6">
                    <span>Customer</span>
                </div>
                <div class="vx-col vx-row sm:w-5/6">
                    <div class="vx-col sm:w-5/6 w-full">
                        <vs-input class="w-full" v-model="salesOrder.Customer.Name" disabled/>
                    </div>
                    <div class="vx-col sm:w-1/6 w-full">
                        <vs-button size="small" class="mr-3 mb-2" @click="browseCustomer()">Browse</vs-button>
                    </div>
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/6">
                    <span>Bill to</span>
                </div>
                <div class="vx-col vx-row sm:w-5/6">
                    <div class="vx-col sm:w-5/6 w-full">
                        <vs-input class="w-full" v-model="salesOrder.BillAddress.Address" disabled/>
                    </div>
                    <div class="vx-col sm:w-1/6 w-full">
                        <vs-button size="small" class="mr-3 mb-2" @click="browseBillTo()">Browse</vs-button>
                    </div>
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/6">
                    <span>Ship to</span>
                </div>
                <div class="vx-col vx-row sm:w-5/6">
                    <div class="vx-col sm:w-5/6 w-full">
                        <vs-input class="w-full" v-model="salesOrder.ShipAddress.Address" disabled/>
                    </div>
                    <div class="vx-col sm:w-1/6 w-full">
                        <vs-button size="small" class="mr-3 mb-2" @click="browseShipTo()">Browse</vs-button>
                    </div>
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/6 w-full">
                    <span>Notes</span>
                </div>
                <div class="vx-col sm:w-5/6 w-full">
                    <vs-textarea maxlength ="255" class="w-full" :label="charactersLeft" name="Notes" v-model="salesOrder.Notes" />
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/6 w-full">
                    <span>Date</span>
                </div>
                <div class="vx-col sm:w-5/6 w-full">
                    <datepicker :inline="false" v-model="salesOrder.Date" placeholder="Select Document Due Date"></datepicker>
                    <!-- <flat-pickr class="w-full" :inline="false" :config="configFlatPickrDate" v-model="salesOrder.Date" placeholder="Select Document Date"></flat-pickr> -->
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/6 w-full">
                    <span>Delivery</span>
                </div>
                <div class="vx-col sm:w-5/6 w-full">
                    <multiselect
                            class="selectExample"
                            v-model="selectDeliveryType"
                            :options="optionDeliveryType"
                            :multiple="false"
                            :allow-empty="false"
                            :group-select="false"
                            :max-height="120"
                            :limit="3"
                            placeholder=" Type to search"
                            track-by="id"
                            label="name"
                            :disabled="false"
                        >
                        <template slot="singleLabel" slot-scope="dt">
                            <span class="option__desc">
                                <span class="option__title">{{ dt.option.name }}</span>
                            </span>
                        </template>

                        <template slot="option" slot-scope="dt">
                            <div class="option__desc">
                                <span class="option__title">{{ dt.option.name }}</span>
                            </div>
                        </template>
                    </multiselect>
                </div>
            </div>
            <!-- 
            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/6 w-full">
                    <span>DO Code</span>
                </div>
                <div class="vx-col sm:w-5/6 w-full">
                    <vs-input class="w-full" v-model="salesOrder.DeliveryOrderCode" :disabled="deliveryOrderCodeIsDisabled"/>
                </div>
            </div>
            -->
            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/6 w-full">
                    <span>Delivery Date</span>
                </div>
                <div class="vx-col sm:w-5/6 w-full">
                    <datepicker :inline="false" v-model="salesOrder.DueDate" placeholder="Select Delivery Date"></datepicker>
                    <!-- <flat-pickr class="w-full" :inline="false" :config="configFlatPickrDueDate" v-model="DueDate" placeholder="Select Document Due Date"></flat-pickr> -->
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/6 w-full">
                    <span>Tax</span>
                </div>
                <div class="vx-col sm:w-5/6 w-full">
                    <multiselect
                            class="selectExample"
                            v-model="selectTax"
                            :options="optionTax"
                            :multiple="false"
                            :allow-empty="false"
                            :group-select="false"
                            :max-height="120"
                            :limit="3"
                            placeholder=" Type to search"
                            track-by="Rate"
                            label="Name"
                            :disabled="false"
                        >

                        <template slot="singleLabel" slot-scope="dt">
                            <span class="option__desc">
                                <span class="option__title">{{ dt.option.Name }}</span>
                            </span>
                        </template>

                        <template slot="option" slot-scope="dt">
                            <div class="option__desc">
                                <span class="option__title">{{ dt.option.Name }}</span>
                            </div>
                        </template>

                    </multiselect>
                </div>
            </div>            
            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/6 w-full">
                    <span>Payment Term</span>
                </div>
                <div class="vx-col sm:w-5/6 w-full">
                    <multiselect
                            class="selectExample"
                            v-model="selectPaymentTerm"
                            :options="optionPaymentTerm"
                            :multiple="false"
                            :allow-empty="false"
                            :group-select="false"
                            :max-height="120"
                            :limit="3"
                            placeholder=" Type to search"
                            track-by="id"
                            label="name"
                            :disabled="false"
                        >
                        <template slot="singleLabel" slot-scope="dt">
                            <span class="option__desc">
                                <span class="option__title">{{ dt.option.name }}</span>
                            </span>
                        </template>

                        <template slot="option" slot-scope="dt">
                            <div class="option__desc">
                                <span class="option__title">{{ dt.option.name }}</span>
                            </div>
                        </template>
                    </multiselect>
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/6 w-full">
                    <span>Line Items</span>
                </div>
                <div class="vx-col sm:w-5/6 w-full">
                    <vs-button size="small" class="mr-3 mb-2" @click="browseItem()">Browse</vs-button>
                </div>
            </div>
            <br>
            <!--
            <div v-if="this.PriceModel != ''">Price Model : {{this.PriceModel}}</div>&nbsp;
            <div v-if="this.DiscountModel != ''">Discount Model : {{this.DiscountModel}}</div>&nbsp;
            <div v-if="this.DiscountUnit != ''">Discount Unit : {{this.DiscountUnit}}</div>&nbsp;
            <div v-if="this.priceProposal != ''">Price Proposal : {{this.priceProposal}}</div>&nbsp;
            -->
        </div>
        <div class="vx-col sm:w-1/2 w-full mb-base">
            <div v-if="this.optionItemAvailableShow" class="vx-col w-full mb-base">
                <div class="vx-row mb-6">                
                    <h3>Item Available</h3>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full">
                        <vs-dropdown vs-trigger-click class="cursor-pointer">
                            <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                                <span class="mr-2">{{this.dataEnd==0 ? 0: (this.limitShow * (this.isActive - 1) + 1)}} - {{this.limitShow * (this.isActive - 1) + dataEnd}} of {{this.recordsTotal}}</span>
                                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                            </div>
                            <vs-dropdown-menu>                
                                <vs-dropdown-item v-for="item in limits" v-bind:key="item" @click="changeLimit(item)">
                                    <span>{{item}}</span>
                                </vs-dropdown-item>
                            </vs-dropdown-menu>
                        </vs-dropdown>
                    </div>
                    <div class="mx-auto"></div>
                    <div class="vx-col sm:w-1/3 w-full">
                        <div class="flex flex-wrap items-center justify-between">
                            <vs-input class="mb-4 md:mb-0 mr-4 w-full" v-model="search" placeholder="Search by : Code, Name, or UOM " />
                        </div>
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col w-full">
                        <vs-table :data="optionItemAvailable" stripe style="width:100%">
                            <template slot="thead">
                                <vs-th>Code</vs-th>
                                <vs-th>Name</vs-th>
                                <vs-th>UOM</vs-th>
                                <vs-th>Stock</vs-th>
                                <vs-th>Action</vs-th>
                            </template>

                            <template slot-scope="{data}">
                                <vs-tr :key="index" v-for="(dt, index) in data">
                                    <vs-td>{{dt.code}}</vs-td>
                                    <vs-td style="align-item-center">{{dt.name}}</vs-td>                                
                                    <vs-td>({{dt.uom}})</vs-td>
                                    <vs-td style="text-align:right">{{dt.quantity}}</vs-td>
                                    <vs-td style="align-item-center">
                                        <feather-icon title="Add" icon="PlusIcon" svgClasses="w-5 h-5 text-success stroke-current" class="ml-2" @click="addItemLine(dt)"/>
                                    </vs-td>
                                </vs-tr>
                            </template>
                        </vs-table>
                        <vs-pagination style="padding-top:5px" :total="maxPage" v-model="currentPage" :prev-icon="$vs.rtl ? 'arrow_forward' : 'arrow_back'" :next-icon="$vs.rtl ? 'arrow_back' : 'arrow_forward'"/>
                    </div>
                </div>
            </div>

            <div v-if="this.optionCustomerShow" class="vx-col w-full mb-base">
                <div class="vx-row mb-6">                
                    <h3>Customer</h3>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full">
                        <vs-dropdown vs-trigger-click class="cursor-pointer">
                            <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                                <span class="mr-2">{{this.dataEnd==0 ? 0: (this.limitShow * (this.isActive - 1) + 1)}} - {{this.limitShow * (this.isActive - 1) + dataEnd}} of {{this.recordsTotal}}</span>
                                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                            </div>
                            <vs-dropdown-menu>                
                                <vs-dropdown-item v-for="item in limits" v-bind:key="item" @click="changeLimit(item)">
                                    <span>{{item}}</span>
                                </vs-dropdown-item>
                            </vs-dropdown-menu>
                        </vs-dropdown>
                    </div>
                    <div class="mx-auto"></div>
                    <div class="vx-col sm:w-1/3 w-full">
                        <div class="flex flex-wrap items-center justify-between">
                            <vs-input class="mb-4 md:mb-0 mr-4 w-full" v-model="search" placeholder="Search by : Code, Name or City " />
                        </div>
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col w-full">
                        <vs-table :data="optionCustomer" stripe style="width:100%">
                            <template slot="thead">
                                <vs-th>Code</vs-th>
                                <vs-th>Name</vs-th>
                                <vs-th>Address</vs-th>
                                <vs-th>Category</vs-th>
                                <vs-th>Action</vs-th>
                            </template>

                            <template slot-scope="{data}">
                                <vs-tr :key="index" v-for="(dt, index) in data">
                                    <vs-td>{{dt.code}}</vs-td>
                                    <vs-td>{{dt.name}}</vs-td>                                
                                    <vs-td>
                                        City : {{dt.city}} <br>
                                        Address : {{dt.address}}
                                    </vs-td>
                                    <vs-td>{{dt.customer_category_name}}</vs-td>
                                    <vs-td style="align-item-center">
                                        <feather-icon title="Add" icon="CheckIcon" svgClasses="w-5 h-5 text-success stroke-current" class="ml-2" @click="selectCustomer(dt)"/>
                                    </vs-td>
                                </vs-tr>
                            </template>
                        </vs-table>
                        <vs-pagination style="padding-top:5px" :total="maxPage" v-model="currentPage" :prev-icon="$vs.rtl ? 'arrow_forward' : 'arrow_back'" :next-icon="$vs.rtl ? 'arrow_back' : 'arrow_forward'"/>
                    </div>
                </div>
            </div>
            
            <!-- <div v-if="this.optionReservationCodeShow" class="vx-col w-full mb-base">
                <datatableReservationCode :territoryId="territoryId" :selectedReservationCode="salesOrder.ReservationCode" v-on:selectReservationCode="updateSelectedReservationCode"></datatableReservationCode>
            </div> -->

            <div v-if="this.optionPromotionCodeShow" class="vx-col w-full mb-base">
                <datatablePromotionCode :territoryId="territoryId" :selectedPromotionCode="promotionCode" v-on:selectPromotionCode="updateSelectedPromotionCode" v-on:limitItemByPromotion="updateLimitItemByPromotion"></datatablePromotionCode>
            </div>

            <div v-if="this.optionBillToShow" class="vx-col w-full mb-base">
                <div class="vx-row mb-6">                
                    <h3>Customer Address - Bill To</h3>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full">
                        <vs-dropdown vs-trigger-click class="cursor-pointer">
                            <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                                <span class="mr-2">{{this.dataEnd==0 ? 0: (this.limitShow * (this.isActive - 1) + 1)}} - {{this.limitShow * (this.isActive - 1) + dataEnd}} of {{this.recordsTotal}}</span>
                                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                            </div>
                            <vs-dropdown-menu>                
                                <vs-dropdown-item v-for="item in limits" v-bind:key="item" @click="changeLimit(item)">
                                    <span>{{item}}</span>
                                </vs-dropdown-item>
                            </vs-dropdown-menu>
                        </vs-dropdown>
                    </div>
                    <div class="mx-auto"></div>
                    <div class="vx-col sm:w-1/3 w-full">
                        <div class="flex flex-wrap items-center justify-between">
                            <vs-input class="mb-4 md:mb-0 mr-4 w-full" v-model="search" placeholder="Search by : Code, Name or City " />
                        </div>
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col w-full">
                        <vs-table :data="optionBillTo" stripe style="width:100%">
                            <template slot="thead">
                                <vs-th>Address</vs-th>
                                <vs-th>Map</vs-th>
                                <vs-th>Contact</vs-th>
                                <vs-th>Work Operation</vs-th>
                                <vs-th>Action</vs-th>
                            </template>

                            <template slot-scope="{data}">
                                <vs-tr :key="index" v-for="(dt, index) in data">
                                    <vs-td>
                                        Address : {{ dt.address }}<br>
                                        Subdistrict : {{ dt.sub_district }}<br>
                                        District : {{ dt.district }}<br>
                                        City : {{ dt.city }}<br>
                                        Province : {{ dt.province }}<br>
                                        Country : {{ dt.country }}<br>
                                        Postal Code : {{ dt.postal_code }}
                                    </vs-td>
                                    <vs-td>
                                        Address : {{ dt.map_address }}<br>
                                        Lat : {{ dt.latitude }}<br>
                                        Long : {{ dt.longitude }}
                                    </vs-td>                                
                                    <vs-td>
                                        Name : {{ dt.contact_name }}<br>
                                        Phone : {{ dt.phone }}<br>
                                        Mobile : {{ dt.contact_mobile }}<br>
                                        Email : {{ dt.email }}<br>
                                        Fax : {{ dt.fax }}
                                    </vs-td>
                                    <vs-td>
                                        <div v-if="dt.work_operation">
                                            <div v-for="item in dt.work_operation.work_operation_detail" :key="item.id" >
                                                {{item.work_day.day}} ({{item.work_time.start_time}} - {{item.work_time.end_time}}) <br>
                                            </div>
                                        </div>
                                    </vs-td>
                                    <vs-td style="align-item-center">
                                        <feather-icon title="Add" icon="CheckIcon" svgClasses="w-5 h-5 text-success stroke-current" class="ml-2" @click="selectBillTo(dt)"/>
                                    </vs-td>
                                </vs-tr>
                            </template>
                        </vs-table>
                        <vs-pagination style="padding-top:5px" :total="maxPage" v-model="currentPage" :prev-icon="$vs.rtl ? 'arrow_forward' : 'arrow_back'" :next-icon="$vs.rtl ? 'arrow_back' : 'arrow_forward'"/>
                    </div>
                </div>
            </div>

            <div v-if="this.optionShipToShow" class="vx-col w-full mb-base">
                <div class="vx-row mb-6">                
                    <h3>Customer Address - Ship To</h3>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col sm:w-1/3 w-full">
                        <vs-dropdown vs-trigger-click class="cursor-pointer">
                            <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                                <span class="mr-2">{{this.dataEnd==0 ? 0: (this.limitShow * (this.isActive - 1) + 1)}} - {{this.limitShow * (this.isActive - 1) + dataEnd}} of {{this.recordsTotal}}</span>
                                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                            </div>
                            <vs-dropdown-menu>                
                                <vs-dropdown-item v-for="item in limits" v-bind:key="item" @click="changeLimit(item)">
                                    <span>{{item}}</span>
                                </vs-dropdown-item>
                            </vs-dropdown-menu>
                        </vs-dropdown>
                    </div>
                    <div class="mx-auto"></div>
                    <div class="vx-col sm:w-1/3 w-full">
                        <div class="flex flex-wrap items-center justify-between">
                            <vs-input class="mb-4 md:mb-0 mr-4 w-full" v-model="search" placeholder="Search by : Code, Name or City " />
                        </div>
                    </div>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col w-full">
                        <vs-table :data="optionShipTo" stripe style="width:100%">
                            <template slot="thead">
                                <vs-th>Address</vs-th>
                                <vs-th>Map</vs-th>
                                <vs-th>Contact</vs-th>
                                <vs-th>Work Operation</vs-th>
                                <vs-th>Action</vs-th>
                            </template>

                            <template slot-scope="{data}">
                                <vs-tr :key="index" v-for="(dt, index) in data">
                                    <vs-td>
                                        Address : {{ dt.address }}<br>
                                        Subdistrict : {{ dt.sub_district }}<br>
                                        District : {{ dt.district }}<br>
                                        City : {{ dt.city }}<br>
                                        Province : {{ dt.province }}<br>
                                        Country : {{ dt.country }}<br>
                                        Postal Code : {{ dt.postal_code }}
                                    </vs-td>
                                    <vs-td>
                                        Address : {{ dt.map_address }}<br>
                                        Lat : {{ dt.latitude }}<br>
                                        Long : {{ dt.longitude }}
                                    </vs-td>                                
                                    <vs-td>
                                        Name : {{ dt.contact_name }}<br>
                                        Phone : {{ dt.phone }}<br>
                                        Mobile : {{ dt.contact_mobile }}<br>
                                        Email : {{ dt.email }}<br>
                                        Fax : {{ dt.fax }}
                                    </vs-td>
                                    <vs-td>
                                        <div v-if="dt.work_operation">
                                            <div v-for="item in dt.work_operation.work_operation_detail" :key="item.id" >
                                                {{item.work_day.day}} ({{item.work_time.start_time}} - {{item.work_time.end_time}}) <br>
                                            </div>
                                        </div>
                                    </vs-td>
                                    <vs-td style="align-item-center">
                                        <feather-icon title="Add" icon="CheckIcon" svgClasses="w-5 h-5 text-success stroke-current" class="ml-2" @click="selectShipTo(dt)"/>
                                    </vs-td>
                                </vs-tr>
                            </template>
                        </vs-table>
                        <vs-pagination style="padding-top:5px" :total="maxPage" v-model="currentPage" :prev-icon="$vs.rtl ? 'arrow_forward' : 'arrow_back'" :next-icon="$vs.rtl ? 'arrow_back' : 'arrow_forward'"/>
                    </div>
                </div>
            </div>

            <div v-if="this.detailStepShow" class="vx-col w-full mb-base">
                <div v-if="(typeof pricingStep[indexStep]) != 'undefined'">
                    <div class="vx-row mb-6">          
                        <h3>Price Engine - Price (Model : Step Ladder)</h3>
                    </div>
                    <div class="vx-row mb-6">
                        <div class="vx-col w-full">
                            <vs-table :data="pricingStep[indexStep]" stripe style="width:100%">
                                <template slot="thead">
                                    <vs-th>Step</vs-th>
                                    <vs-th>Qty</vs-th>
                                    <vs-th>Price</vs-th>
                                    <vs-th>Qty x Price</vs-th>
                                </template>

                                <template slot-scope="{data}">
                                    <vs-tr :key="index" v-for="(dt, index) in data">
                                        <vs-td style="text-align:right">{{ index+1 }}</vs-td>
                                        <vs-td style="text-align:right">{{ dt.qty }}</vs-td>
                                        <vs-td style="text-align:right">{{ formatCurrency(dt.price) }}</vs-td>
                                        <vs-td style="text-align:right">{{ formatCurrency(dt.qty * dt.price) }}</vs-td>
                                    </vs-tr>
                                </template>
                            </vs-table>
                        </div>
                    </div>
                </div>
                <div v-if="(typeof discountStep[indexStep]) != 'undefined'">
                    <div class="vx-row mb-6">                
                        <h3>Price Engine - Discount (Model : Step Ladder - Unit : Line)</h3>
                    </div>
                    <div class="vx-row mb-6">
                        <div class="vx-col w-full">
                            <vs-table :data="discountStep[indexStep]" stripe style="width:100%">
                                <template slot="thead">
                                    <vs-th>Step</vs-th>
                                    <vs-th>Nominal</vs-th>
                                    <vs-th>Percentage</vs-th>
                                    <vs-th>Amount</vs-th>                                
                                </template>

                                <template slot-scope="{data}">
                                    <vs-tr :key="index" v-for="(dt, index) in data">
                                        <vs-td style="text-align:right">{{ index+1 }}</vs-td>
                                        <vs-td style="text-align:right">{{ formatCurrency(dt.nominal) }}</vs-td>
                                        <vs-td style="text-align:right">{{ dt.percentage }}%</vs-td>
                                        <vs-td style="text-align:right">{{ formatCurrency(dt.amount) }}</vs-td>
                                    </vs-tr>
                                </template>
                            </vs-table>
                        </div>
                    </div>
                </div>
                <div class="vx-row mb-6" style="width:100%; font-size:10pt;">
                    <div class="vx-col sm:w-4/5 w-full" style="text-align:right">
                        <span>Sub Total Line</span>
                    </div>
                    <div class="vx-col sm:w-1/5 w-full">
                        <input class="vs-inputx vs-input--input" style="text-align:right" :value="formatCurrency(itemsSubtotalEngine[indexStep])" disabled />
                    </div>
                </div>
                <div class="vx-row mb-6" style="width:100%; font-size:10pt;">
                    <div class="vx-col sm:w-4/5 w-full" style="text-align:right">
                        <span>Total Discount Line</span>
                    </div>
                    <div class="vx-col sm:w-1/5 w-full">
                        <input class="vs-inputx vs-input--input" style="text-align:right" :value="formatCurrency(itemsDiscountEngine[indexStep])" disabled />
                    </div>
                </div>
                <div class="vx-row mb-6" style="width:100%; font-size:10pt;">
                    <div class="vx-col sm:w-4/5 w-full" style="text-align:right">
                        <span>Tax Line</span>
                    </div>
                    <div class="vx-col sm:w-1/5 w-full">
                        <input class="vs-inputx vs-input--input" style="text-align:right" :value="formatCurrency(itemsTaxEngine[indexStep])" disabled />
                    </div>
                </div>
                <div class="vx-row mb-6" style="width:100%; font-size:10pt;">
                    <div class="vx-col sm:w-4/5 w-full" style="text-align:right">
                        <span>Total Line</span>
                    </div>
                    <div class="vx-col sm:w-1/5 w-full">
                        <input class="vs-inputx vs-input--input" style="text-align:right" :value="formatCurrency(itemsLineTotalEngine[indexStep])" disabled />
                    </div>
                </div>
            </div>

            <div v-if="this.detailTotalDiscountStepShow" class="vx-col w-full mb-base">
                <div class="vx-row mb-6">                
                    <h3>Price Engine - Discount (Model : Step Ladder - Unit : Total)</h3>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col w-full">
                        <vs-table :data="totalDiscountStep" stripe style="width:100%">
                            <template slot="thead">
                                <vs-th>Step</vs-th>
                                <vs-th>Nominal</vs-th>
                                <vs-th>Percentage</vs-th>
                                <vs-th>Amount</vs-th>
                            </template>

                            <template slot-scope="{data}">
                                <vs-tr :key="index" v-for="(dt, index) in data">
                                    <vs-td style="text-align:right">{{ index+1 }}</vs-td>
                                    <vs-td style="text-align:right">{{ formatCurrency(dt.nominal) }}</vs-td>
                                    <vs-td style="text-align:right">{{ formatCurrency(dt.percentage) }}%</vs-td>
                                    <vs-td style="text-align:right">{{ formatCurrency(dt.amount) }}</vs-td>
                                </vs-tr>
                            </template>
                        </vs-table>
                    </div>
                </div>
                <div class="vx-row mb-6" style="width:100%; font-size:10pt;">
                    <div class="vx-col sm:w-4/5 w-full" style="text-align:right">
                        <span>Total Discount</span>
                    </div>
                    <div class="vx-col sm:w-1/5 w-full">
                        <input class="vs-inputx vs-input--input" style="text-align:right" v-model="totalDiscount" disabled />
                    </div>
                </div>
            </div>
        </div>
        <hr>
        <br>
        <div class="vx-col w-full mb-base">
            <div class="vx-row mb-6">
                <div class="vx-col w-full">
                    <vs-table :data="itemsCode" stripe style="width:100%">
                        <template slot="thead">
                            <vs-th >No</vs-th>
                            <vs-th >Code</vs-th>
                            <vs-th >Name</vs-th>
                            <vs-th>Qty Req.</vs-th>
                            <vs-th>Qty Adj.</vs-th>
                            <vs-th>Pricing</vs-th>
                            <vs-th>Action</vs-th>
                        </template>

                        <template slot-scope="{data}">
                            <vs-tr :key="index" v-for="(dt, index) in data" :class="{'text-danger': itemsLineTotal[index]==0}">
                                <vs-td style="align-item-right">{{index+1}}</vs-td>
                                <vs-td style="align-item-center">{{itemsCode[index]}}</vs-td>
                                <vs-td style="align-item-center">{{itemsName[index]}}</vs-td>
                                <vs-td>{{itemsQuantityReq[index]}} ({{itemsUom[index]}})</vs-td>
                                <vs-td>
                                    <vue-number-input
                                        inline
                                        controls
                                        :min="0"
                                        :step="1"
                                        size="small"
                                        v-model="itemsQuantityAdj[index]"
                                        :value="0"
                                        @keypress="isNumber($event)"
                                    />
                                </vs-td>
                                <vs-td>
                                    <div v-if="priceProposal == 'Allow' ">
                                        Price Proposal<br>
                                        <vue-number-input
                                            inline
                                            controls
                                            :min="0"
                                            :step="1"
                                            size="small"
                                            v-model="itemsPriceCustom[index]"
                                            :value="0"
                                            @keypress="isNumber($event)"
                                            @change="changePrice()"
                                        /><br><br>
                                    </div>
                                    Price : {{ formatCurrency(itemsPrice[index]) }}<br>
                                    Subtotal : {{ formatCurrency(itemsSubtotal[index]) }} <br>
                                    Disc : {{ formatCurrency(itemsDiscount[index]) }} <br>
                                    Tax : {{ formatCurrency(itemsTax[index]) }} <br>
                                    Line Total : {{ formatCurrency(itemsLineTotal[index]) }}
                                </vs-td>
                                <vs-td style="align-item-center">
                                    <feather-icon v-if="pricingStep.length > 0"  title="Detail" icon="SearchIcon" svgClasses="w-5 h-5 text-info stroke-current" class="ml-2" @click="detailStep(index)"/>
                                    <feather-icon title="Delete" icon="TrashIcon" svgClasses="w-5 h-5 text-danger stroke-current" class="ml-2" @click="deleteItemLine(index)"/>
                                </vs-td>
                            </vs-tr>
                        </template>
                    </vs-table>
                </div>
            </div>

            <!-- total order = harga satuan dikali jumlah 
            total diskon = totalnya diskon
            total nett = total order - total diskon
            total pajak = total nett * pajak
            total bayar = total nett + total pajak -->

            <div class="vx-row mb-6" style="width:100%; font-size:10pt;">
                <div class="vx-col sm:w-4/5 w-full" style="text-align:right">
                    <span>Total Order</span>
                </div>
                <div class="vx-col sm:w-1/5 w-full">
                    <input class="vs-inputx vs-input--input" style="text-align:right" v-model="totalBrutto" disabled />
                </div>
            </div>
            <div class="vx-row mb-6" style="width:100%; font-size:10pt;">
                <div class="vx-col sm:w-4/5 w-full" style="text-align:right">
                    <span>Total Discount</span>
                    <feather-icon v-if="totalDiscountStep.length > 0"  title="Detail" icon="SearchIcon" svgClasses="w-5 h-5 text-info stroke-current" class="ml-2" @click="detailTotalDiscountStep()"/>
                </div>
                <div class="vx-col sm:w-1/5 w-full">
                    <input class="vs-inputx vs-input--input" style="text-align:right" v-model="totalDiscount" disabled />
                </div>
            </div>
            <div class="vx-row mb-6" style="width:100%; font-size:10pt;">
                <div class="vx-col sm:w-4/5 w-full" style="text-align:right">
                    <span>Total Nett</span>
                </div>
                <div class="vx-col sm:w-1/5 w-full">
                    <input class="vs-inputx vs-input--input" style="text-align:right" v-model="totalNetto" disabled />
                </div>
            </div>
            <div class="vx-row mb-6" style="width:100%; font-size:10pt;">
                <div class="vx-col sm:w-4/5 w-full" style="text-align:right">
                    <span>Tax</span>
                </div>
                <div class="vx-col sm:w-1/5 w-full">
                    <input class="vs-inputx vs-input--input" style="text-align:right" v-model="totalTax" disabled />
                </div>
            </div>
            <div class="vx-row mb-6" style="width:100%; font-size:10pt;">
                <div class="vx-col sm:w-4/5 w-full" style="text-align:right">
                    <span>Charges</span>
                </div>
                <div class="vx-col sm:w-1/5 w-full">
                    <input @keypress="isNumber($event)" @change="changeCharge()" class="vs-inputx vs-input--input" style="text-align:right" v-model="salesOrder.SubtotalCharge"/>
                </div>
            </div>
            <div class="vx-row mb-6" style="width:100%; font-size:10pt;">
                <div class="vx-col sm:w-4/5 w-full" style="text-align:right">
                    <span>Total</span>
                </div>
                <div class="vx-col sm:w-1/5 w-full">
                    <input class="vs-inputx vs-input--input" style="text-align:right" v-model="totalBilling" disabled />
                </div>
            </div>
            <div class="vx-row">
                <div v-if="salesOrder.Code != ''" class="vx-col sm:w-6/12 w-full">
                    <vs-button v-if="calculated" class="mr-3 mb-2" v-on:click="SubmitForm()">Submit</vs-button>
                    <vs-button v-if="calculated != true" class="mr-3 mb-2" v-on:click="CalculateSOLine()">Calculate</vs-button>
                    <vs-button v-if="update" color="warning" class="mr-3 mb-2" @click="ResetForm()">Reset</vs-button>
                    <vs-button v-if="update" color="danger" class="mr-3 mb-2" @click="CancelForm()">Cancel</vs-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import VueNumberInput from "@chenfengyuan/vue-number-input";
import flatPickr from 'vue-flatpickr-component';
import ConfirmDatePlugin from 'flatpickr/dist/plugins/confirmDate/confirmDate.js';
import 'flatpickr/dist/flatpickr.css';
import fieldPromotionCode from '../component/field_promotion_code.vue'
import datatablePromotionCode from "../component/datatable_promotion_code.vue";
// import fieldReservationCode from '../component/field_reservation_code.vue'
// import datatableReservationCode from "../component/datatable_reservation_code.vue";
// import moment from 'moment'

export default {
    props: ["territoryCode", "territoryId", "selectedSO", "optionTerritory"],
    components: {
        Datepicker,
        VueNumberInput,
        flatPickr,
        fieldPromotionCode,
        datatablePromotionCode,
        // fieldReservationCode,
        // datatableReservationCode
    },
    mounted(){
        // console.log("userLogin : ", this.$userLogin);
        if(this.selectedSO && this.selectedSO.Code) {
            this.resetSalesOrder = Object.assign({}, this.selectedSO);
            this.salesOrder = this.selectedSO;

            this.salesOrder.Date = new Date(this.salesOrder.Date)
            this.salesOrder.DueDate = new Date(this.salesOrder.DueDate)
            
            this.update = true;
            // this.external = true;
            this.external = false; // sementara dibikin true, belom ada object saleschannel di api SO
            this.selectTerritory = {
                code: this.selectedSO.Territory.Code,
                name: this.selectedSO.Territory.Name
            };
            this.selectTax = {
                Name: this.selectedSO.Tax.Name,
                Rate: this.selectedSO.Tax.Rate
            };

            if(this.selectedSO.SalesChannel.PriceProposal == "Allow") {
                this.priceProposal = "Allow";
            } else {
                this.priceProposal = "Disallow";
            }

            for (var i = 0; i < this.salesOrder.SalesOrderLine.length; i++) {
                this.itemsCode.push(this.salesOrder.SalesOrderLine[i].ItemCode);
                this.itemsName.push(this.salesOrder.SalesOrderLine[i].ItemName);
                this.itemsUom.push(this.salesOrder.SalesOrderLine[i].ItemUnit);
                this.itemsQuantityReq.push(this.salesOrder.SalesOrderLine[i].Quantity);
                this.itemsQuantityAdj.push(0);

                this.itemsPrice.push(this.salesOrder.SalesOrderLine[i].Price);
                if(this.salesOrder.SalesOrderLine[i].IsCustomPrice == 1) {
                    this.itemsPriceCustom.push(this.salesOrder.SalesOrderLine[i].Price);
                } else {
                    this.itemsPriceCustom.push(0);
                }

                this.itemsSubtotal.push(this.salesOrder.SalesOrderLine[i].Subtotal);
                this.itemsDiscount.push(this.salesOrder.SalesOrderLine[i].Discount);
                this.itemsTax.push(this.salesOrder.SalesOrderLine[i].TaxAmount);
                this.itemsLineTotal.push(this.salesOrder.SalesOrderLine[i].Total);

                this.itemsSubtotalEngine.push(this.salesOrder.SalesOrderLine[i].Subtotal);
                this.itemsDiscountEngine.push(this.salesOrder.SalesOrderLine[i].Discount);
                this.itemsTaxEngine.push(this.salesOrder.SalesOrderLine[i].TaxAmount);
                this.itemsLineTotalEngine.push(this.salesOrder.SalesOrderLine[i].Total);

                // record price model step ladder
                if (this.salesOrder.SalesOrderLine[i].Price == 0 && this.salesOrder.SalesOrderLine[i].SalesOrderLinePrice.length > 0) {
                    this.itemsPrice[i] = "step-ladder";
                    var arr_temp = [];
                    for (var j = 0; j < this.salesOrder.SalesOrderLine[i].SalesOrderLinePrice.length; j++) {
                        var pricingStep = this.salesOrder.SalesOrderLine[i].SalesOrderLinePrice[j];
                        const temp = new Object();
                        temp.step = pricingStep.Step;
                        temp.qty = pricingStep.Qty;
                        temp.price = pricingStep.Price;
                        arr_temp[j] = temp;
                    }
                    this.pricingStep[i] = arr_temp;
                }

                // record discount model step ladder, unit line
                if (this.salesOrder.SalesOrderLine[i].SalesOrderLineDiscount.length > 0) {
                    var arr_temp_disc = [];
                    for (var k = 0; k < this.salesOrder.SalesOrderLine[i].SalesOrderLineDiscount.length; k++) {
                        var discountStep = this.salesOrder.SalesOrderLine[i].SalesOrderLineDiscount[k];
                        const temp = new Object();
                        temp.step = discountStep.Step;
                        temp.percentage = discountStep.Percentage;
                        temp.amount = discountStep.Amount;
                        arr_temp_disc[k] = temp;
                    }
                    this.discountStep[i] = arr_temp_disc;
                }
            }

            if (this.salesOrder.SalesOrderDiscount.length > 0) {
                this.totalDiscountStep = this.salesOrder.SalesOrderDiscount
            }

            // record discount model step ladder, unit final
            if (this.salesOrder.SalesOrderDiscount.length > 0) {
                for (var l = 0; l < this.salesOrder.SalesOrderDiscount.length; l++) {
                    var totalDiscountStep = this.salesOrder.SalesOrderDiscount[l];
                    const temp = new Object();
                    temp.step = totalDiscountStep.Step;
                    temp.nominal = totalDiscountStep.Nominal;
                    temp.percentage = totalDiscountStep.Percentage;
                    temp.amount = totalDiscountStep.Amount;
                    this.totalDiscountStep[l] = temp
                }
            }

            this.totalBrutto = this.formatCurrency(this.salesOrder.Subtotal); // total order = harga satuan dikali jumlah 
            this.totalDiscount = this.formatCurrency(this.salesOrder.Discount); // total diskon = totalnya diskon
            this.totalNetto = this.formatCurrency(this.salesOrder.Subtotal - this.salesOrder.Discount); // total nett = total order - total diskon
            this.totalTax = this.formatCurrency(this.salesOrder.TaxAmount); // total pajak = total nett * pajak
            this.totalBilling = this.formatCurrency(this.salesOrder.Total); // total bayar = total nett + total pajak
            
            for (var m = 0; m < this.salesOrder.SalesOrderPromo.length; m++) {
                this.promotionCode.push(this.salesOrder.SalesOrderPromo[m].PromoCode);
            }

            // this.CalculateTotal();
        } else {
            this.selectTerritory = {
                code: this.territoryCode,
                name: ''
            };
           this.setDefaultDate();
           this.resetSalesOrder = Object.assign({}, this.salesOrder);
        }

        this.getOptionTax();
        this.getOptionPaymentTerm();
        this.getOptionDeliveryType();
    },
    computed: {
        DueDate: {
            get() {
                var d = new Date()
                if(this.selectedSO && this.selectedSO.DueDate){
                    d = new Date(this.selectedSO.DueDate)
                    d.setHours(d.getHours() - 7)
                } else {
                    d = new Date(this.salesOrder.DueDate)
                }
                return d
            },
            set(val) {
                this.DueDatePost = val
                return val
            }
        },
        charactersLeft() {
            var char = this.salesOrder.Notes.length,
                limit = 255;

            return "counter " + (limit - char);
        },
        currentPage: {
            get() {
                return 1;
            },
            set(page) {
                if(this.optionItemAvailableShow) {
                    this.getOptionItemAvailable(page);
                }
                if(this.optionCustomerShow) {
                    this.getOptionCustomer(page);
                }
                if(this.optionBillToShow) {
                    this.getOptionBillTo(page);
                }
                if(this.optionShipToShow) {
                    this.getOptionShipTo(page);
                }
                return page;
            }
        },
    },
    watch: {
        search: function() {
            if(this.optionItemAvailableShow) {
                this.getOptionItemAvailable();
            }
            if(this.optionCustomerShow) {
                this.getOptionCustomer();
            }
            if(this.optionBillToShow) {
                this.getOptionBillTo();
            }
            if(this.optionShipToShow) {
                this.getOptionShipTo();
            }
        },
        itemsQuantityReq: function() {
            this.calculated = false;
        },
        itemsQuantityAdj: function() {
            this.calculated = false;
        },
        selectTax: function() {
            this.calculated = false;
        },
        selectDeliveryType: function(newValue, oldValue){
            if(newValue.parcel_services == 1) {
                this.deliveryOrderCodeIsDisabled = false;
            } else {
                this.deliveryOrderCodeIsDisabled = true;
                if(this.selectedSO && this.selectedSO.Code) {
                    this.salesOrder.DeliveryOrderCode = this.selectedSO.DeliveryOrderCode;
                } else {
                    this.salesOrder.DeliveryOrderCode = "";
                }
            }
        },
        territoryCode: function () {
            this.selectTerritory = {
                code: this.territoryCode,
                name: ''
            };
        }
    },
    data: () => ({
        configFlatPickrDueDate: {
            // wrap: true, // set wrap to true only when using 'input-group'
            altFormat: 'F j, Y',
            // altInput: true,
            dateFormat: 'j F Y H:i',
            enableTime: true,
            minTime: "00:00",
            plugins: [new ConfirmDatePlugin()]
        },

        configFlatPickrDate: {
            // wrap: true, // set wrap to true only when using 'input-group'
            altFormat: 'F j, Y',
            // altInput: true,
            dateFormat: 'j F Y',
            enableTime: false,
        },

        hour: "",
        minute: "",

        update: false,
        calculated: false,
        external: false,
        deliveryOrderCodeIsDisabled: true,

        selectTerritory:{},
        selectTax:{
            Name: "",
            Rate: 0
        },
        selectPaymentTerm:{},
        selectDeliveryType:{},

        resetSalesOrder: {},
        salesOrder: {
            Code: "",
            PoReferenceCode: "",
            DeliveryOrderCode: "",
            ReservationCode: "",
            Customer: {
                ID: 0,
                Name: "",
                Code: "",
            },
            Territorry: {
                Code: ""
            },
            Company: {
                Code: ""
            },
            SalesChannel: {
                Code: "",
                PriceProposal: "Disallow",
            },
            BillToAddressID: 0,
            BillAddress: {
                Address: "",
                City: ""
            },
            ShipmentToAddressID: 0,
            ShipAddress: {
                Address: "",
                City: ""
            },
            Date: "",
            DueDate: "",
            AllowPartial: 1,
            Notes: "",
            PaymentTermID: 0,
            SubtotalCharge: 0,
        },
        DueDatePost: "",

        priceProposal: "Disallow",

        itemsCode: [],
        itemsName: [],
        itemsUom: [],
        itemsQuantityReq: [],
        itemsQuantityAdj: [],

        itemsPrice: [],
        itemsPriceCustom: [],
        itemsSubtotal: [],
        itemsDiscount: [],
        itemsTax: [],
        itemsLineTotal: [],
        
        itemsSubtotalEngine: [],
        itemsDiscountEngine: [],
        itemsTaxEngine: [],
        itemsLineTotalEngine: [],

        pricingStep: [],
        discountStep: [],
        totalDiscountStep: [],
        
        totalBrutto: 0,
        totalDiscount: 0,
        totalNetto: 0,
        totalTax: 0,
        totalBilling: 0,
        promotionCode:[],
        limitedItem:[],

        optionCustomer: [{}],
        optionItemAvailable: [{}],
        optionBillTo: [{}],
        optionShipTo: [{}],
        optionTax: [{}],
        optionPaymentTerm: [{}],
        optionDeliveryType: [{}],

        optionItemAvailableShow: false,
        optionCustomerShow: false,
        optionBillToShow: false,
        optionShipToShow: false,
        optionPromotionCodeShow:false,
        optionReservationCodeShow: false,
        detailStepShow: false,
        detailTotalDiscountStepShow: false,

        indexStep: 0,

        PriceModel: '',
        DiscountModel: '',
        DiscountUnit: '',

        limits: [10],
        limitShow: 10,
        drawData: 0,
        isActive: 1,
        search: "",
        recordsTotal: 0,
        maxPage: 2,
        dataEnd: 0,
    }),
    methods: {
        checkDate() {
            let soDate = this.salesOrder.Date
            let deliveryDate = this.salesOrder.DueDate

            if(soDate.getFullYear() > deliveryDate.getFullYear()) {
                console.log(soDate.getFullYear(), deliveryDate.getFullYear())
                return false;
            }

            if(soDate.getMonth() > deliveryDate.getMonth()) {
                console.log(soDate.getMonth(), deliveryDate.getMonth())
                return false;
            }

            if(soDate.getMonth() == deliveryDate.getMonth() && soDate.getDate() > deliveryDate.getDate()) {
                console.log(soDate.getDate(), deliveryDate.getDate())
                return false;
            }

            return true
        },
        scrollToTop() {
            window.scrollTo(0,0);
        },
        formatCurrency(nominal) {
            if(nominal && nominal > 0) { // nominal > 0 untuk handle return NaN
                nominal = (nominal/1).toFixed(2).replace('.', ',');
                return nominal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
            } else {
                return 0;
            }
        },
        isNumber: function(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        containsKey(obj, key ) {
            return Object.keys(obj).includes(key);
        },
        ResetForm() {
            this.calculated = false;
            this.salesOrder = Object.assign({}, this.resetSalesOrder);

            this.itemsCode = [];
            this.itemsName = [];
            this.itemsUom = [];
            this.itemsQuantityReq = [];
            this.itemsQuantityAdj = [];

            this.itemsPrice = [];
            this.itemsPriceCustom = [];
            this.itemsSubtotal = [];
            this.itemsDiscount = [];
            this.itemsTax = [];
            this.itemsLineTotal = [];
            
            this.itemsSubtotalEngine = [];
            this.itemsDiscountEngine = [];
            this.itemsTaxEngine = [];
            this.itemsLineTotalEngine = [];

            this.pricingStep = [];
            this.discountStep = [];
            this.totalDiscountStep = [];

            this.totalBrutto = 0;
            this.totalDiscount = 0;
            this.totalNetto = 0;
            this.totalTax = 0;
            this.totalBilling = 0;

            this.promotionCode = [],
            this.limitedItem = [],

            this.optionItemAvailableShow = false;
            this.optionCustomerShow = false;
            this.optionBillToShow = false;
            this.optionShipToShow = false;
            this.optionPromotionCodeShow = false;
            this.optionReservationCodeShow = false;
            this.detailStepShow = false;
            this.detailTotalDiscountStepShow = false;

            this.indexStep = 0;

            if(this.selectedSO.SalesChannel.PriceProposal == "Allow") {
                this.priceProposal = "Allow";
            } else {
                this.priceProposal = "Disallow";
            }

            for (var i = 0; i < this.salesOrder.SalesOrderLine.length; i++) {
                this.itemsCode.push(this.salesOrder.SalesOrderLine[i].ItemCode);
                this.itemsName.push(this.salesOrder.SalesOrderLine[i].ItemName);
                this.itemsUom.push(this.salesOrder.SalesOrderLine[i].ItemUnit);
                this.itemsQuantityReq.push(this.salesOrder.SalesOrderLine[i].Quantity);
                this.itemsQuantityAdj.push(0);

                this.itemsPrice.push(this.salesOrder.SalesOrderLine[i].Price);
                if(this.salesOrder.SalesOrderLine[i].IsCustomPrice == 1) {
                    this.itemsPriceCustom.push(this.salesOrder.SalesOrderLine[i].Price);
                } else {
                    this.itemsPriceCustom.push(0);
                }

                this.itemsSubtotal.push(this.salesOrder.SalesOrderLine[i].Subtotal);
                this.itemsDiscount.push(this.salesOrder.SalesOrderLine[i].Discount);
                this.itemsTax.push(this.salesOrder.SalesOrderLine[i].TaxAmount);
                this.itemsLineTotal.push(this.salesOrder.SalesOrderLine[i].Total);

                this.itemsSubtotalEngine.push(this.salesOrder.SalesOrderLine[i].Subtotal);
                this.itemsDiscountEngine.push(this.salesOrder.SalesOrderLine[i].Discount);
                this.itemsTaxEngine.push(this.salesOrder.SalesOrderLine[i].TaxAmount);
                this.itemsLineTotalEngine.push(this.salesOrder.SalesOrderLine[i].Total);

                // record price model step ladder
                if (this.salesOrder.SalesOrderLine[i].Price == 0 && this.salesOrder.SalesOrderLine[i].SalesOrderLinePrice.length > 0) {
                    this.itemsPrice[i] = "step-ladder";
                    var arr_temp = [];
                    for (var j = 0; j < this.salesOrder.SalesOrderLine[i].SalesOrderLinePrice.length; j++) {
                        var pricingStep = this.salesOrder.SalesOrderLine[i].SalesOrderLinePrice[j];
                        const temp = new Object();
                        temp.step = pricingStep.Step;
                        temp.qty = pricingStep.Qty;
                        temp.price = pricingStep.Price;
                        arr_temp[j] = temp;
                    }
                    this.pricingStep[i] = arr_temp;
                }

                // record discount model step ladder, unit line
                if (this.salesOrder.SalesOrderLine[i].SalesOrderLineDiscount.length > 0) {
                    var arr_temp_disc = [];
                    for (var k = 0; k < this.salesOrder.SalesOrderLine[i].SalesOrderLineDiscount.length; k++) {
                        var discountStep = this.salesOrder.SalesOrderLine[i].SalesOrderLineDiscount[k];
                        const temp = new Object();
                        temp.step = discountStep.Step;
                        temp.percentage = discountStep.Percentage;
                        temp.amount = discountStep.Amount;
                        arr_temp_disc[k] = temp;
                    }
                    this.discountStep[i] = arr_temp_disc;
                }
            }

            if (this.salesOrder.SalesOrderDiscount.length > 0) {
                this.totalDiscountStep = this.salesOrder.SalesOrderDiscount
            }

            // record discount model step ladder, unit final
            if (this.salesOrder.SalesOrderDiscount.length > 0) {
                for (var l = 0; l < this.salesOrder.SalesOrderDiscount.length; l++) {
                    var totalDiscountStep = this.salesOrder.SalesOrderDiscount[l];
                    const temp = new Object();
                    temp.step = totalDiscountStep.Step;
                    temp.nominal = totalDiscountStep.Nominal;
                    temp.percentage = totalDiscountStep.Percentage;
                    temp.amount = totalDiscountStep.Amount;
                    this.totalDiscountStep[l] = temp
                }
            }

            this.totalBrutto = this.formatCurrency(this.salesOrder.Subtotal); // total order = harga satuan dikali jumlah 
            this.totalDiscount = this.formatCurrency(this.salesOrder.Discount); // total diskon = totalnya diskon
            this.totalNetto = this.formatCurrency(this.salesOrder.Subtotal - this.salesOrder.Discount); // total nett = total order - total diskon
            this.totalTax = this.formatCurrency(this.salesOrder.TaxAmount); // total pajak = total nett * pajak
            this.totalBilling = this.formatCurrency(this.salesOrder.Total); // total bayar = total nett + total pajak
            
            for (var m = 0; m < this.salesOrder.SalesOrderPromo.length; m++) {
                this.promotionCode.push(this.salesOrder.SalesOrderPromo[m].PromoCode);
            }
        },
        CancelForm() {
            this.calculated = false;
            this.resetSalesOrder = {
                Code: "",
                PoReferenceCode: "",
                DeliveryOrderCode: "",
                Customer: {
                    ID: 0,
                    Name: "",
                    Code: "",
                },
                Territorry: {
                    Code: ""
                },
                Company: {
                    Code: ""
                },
                SalesChannel: {
                    Code: "",
                    PriceProposal: "Disallow",
                },
                BillToAddressID: 0,
                BillAddress: {
                    Address: "",
                    City: ""
                },
                ShipmentToAddressID: 0,
                ShipAddress: {
                    Address: "",
                    City: ""
                },
                Date: "",
                DueDate: "",
                AllowPartial: 1,
                Notes: "",
                PaymentTermID: 0,
                SubtotalCharge: 0,
            };
            this.salesOrder = Object.assign({}, this.resetSalesOrder);
            this.selectTerritory = {};
            this.selectTax = {
                Name: "",
                Rate: 0
            };
            this.selectPaymentTerm = {};
            this.selectDeliveryType = {};
            this.calculated = false;
            this.update = false;
            this.external = false;
            this.deliveryOrderCodeIsDisabled = true;
            this.priceProposal = "Disallow";

            this.itemsCode = [];
            this.itemsName = [];
            this.itemsUom = [];
            this.itemsQuantityReq = [];
            this.itemsQuantityAdj = [];

            this.itemsPrice = [];
            this.itemsPriceCustom = [];
            this.itemsSubtotal = [];
            this.itemsDiscount = [];
            this.itemsTax = [];
            this.itemsLineTotal = [];
            
            this.itemsSubtotalEngine = [];
            this.itemsDiscountEngine = [];
            this.itemsTaxEngine = [];
            this.itemsLineTotalEngine = [];

            this.pricingStep = [];
            this.discountStep = [];
            this.totalDiscountStep = [];

            this.totalBrutto = 0;
            this.totalDiscount = 0;
            this.totalNetto = 0;
            this.totalTax = 0;
            this.totalBilling = 0;

            this.promotionCode = [],
            this.limitedItem = [],

            this.optionItemAvailableShow = false;
            this.optionCustomerShow = false;
            this.optionBillToShow = false;
            this.optionShipToShow = false;
            this.optionPromotionCodeShow = false;
            this.optionReservationCodeShow = false;
            this.detailStepShow = false;
            this.detailTotalDiscountStepShow = false;

            this.indexStep = 0;

            this.PriceModel = '';
            this.DiscountModel = '';
            this.DiscountUnit = '';

            this.$emit("selectSalesOrder", {});
        },
        browseItem() {
            this.search = "";
            this.indexStep = 0;
            this.optionItemAvailableShow = true;
            this.optionCustomerShow = false;
            this.optionBillToShow = false;
            this.optionShipToShow = false;
            this.detailStepShow = false;
            this.detailTotalDiscountStepShow = false;
            this.optionPromotionCodeShow = false;
            this.optionReservationCodeShow = false;
            this.getOptionItemAvailable();
            this.scrollToTop();
        },
        browseCustomer() {
            this.search = "";
            this.indexStep = 0;
            this.optionCustomerShow = true;
            this.optionItemAvailableShow = false;
            this.optionBillToShow = false;
            this.optionShipToShow = false;
            this.detailStepShow = false;
            this.detailTotalDiscountStepShow = false;
            this.optionPromotionCodeShow = false;
            this.optionReservationCodeShow = false;
            this.getOptionCustomer();
            this.scrollToTop();
        },
        browseBillTo() {
            // console.log(optionCustomer,optionItemAvailable,optionBillTo,optionShipTo);
            // console.log("before", this.optionBillTo);
            this.search = "";
            this.indexStep = 0;
            this.optionBillToShow = true;
            this.optionCustomerShow = false;
            this.optionItemAvailableShow = false;
            this.optionShipToShow = false;
            this.detailStepShow = false;
            this.detailTotalDiscountStepShow = false;
            this.optionPromotionCodeShow = false;
            this.optionReservationCodeShow = false;
            this.getOptionBillTo();
            this.scrollToTop();
            // console.log("after", this.optionBillTo);
        },
        browseShipTo() {
            this.search = "";
            this.indexStep = 0;
            this.optionShipToShow = true;
            this.optionCustomerShow = false;
            this.optionItemAvailableShow = false;
            this.optionBillToShow = false;
            this.detailStepShow = false;
            this.detailTotalDiscountStepShow = false;
            this.optionPromotionCodeShow = false;
            this.optionReservationCodeShow = false;
            this.getOptionShipTo();
            this.scrollToTop();
        },
        detailStep(index) {
            this.search = "";
            this.indexStep = index;
            this.detailStepShow = true;
            this.optionShipToShow = false;
            this.optionCustomerShow = false;
            this.optionItemAvailableShow = false;
            this.optionBillToShow = false;
            this.detailTotalDiscountStepShow = false;
            this.optionPromotionCodeShow = false;
            this.optionReservationCodeShow = false;
            this.scrollToTop();
        },
        browsePromotionCode() {
            this.search = "";
            this.indexStep = 0;
            this.optionPromotionCodeShow = true;
            this.detailStepShow = false;
            this.optionShipToShow = false;
            this.optionCustomerShow = false;
            this.optionItemAvailableShow = false;
            this.optionBillToShow = false;
            this.detailTotalDiscountStepShow = false;
            this.optionReservationCodeShow = false;
        },
        browseReservationCode() {
            this.search = "";
            this.indexStep = 0;
            this.optionReservationCodeShow = true;
            this.detailStepShow = false;
            this.optionShipToShow = false;
            this.optionCustomerShow = false;
            this.optionItemAvailableShow = false;
            this.optionBillToShow = false;
            this.optionPromotionCodeShow = false;
            this.detailTotalDiscountStepShow = false;
        },
        detailTotalDiscountStep() {
            this.search = "";
            this.indexStep = 0;
            this.detailTotalDiscountStepShow = true;
            this.optionShipToShow = false;
            this.optionCustomerShow = false;
            this.optionItemAvailableShow = false;
            this.optionBillToShow = false;
            this.detailStepShow = false;
            this.scrollToTop();
        },
        addItemLine(item) {
            this.calculated = false;
            if(this.external == false) {
                this.itemsCode.push(item.code);
                this.itemsName.push(item.name);
                this.itemsUom.push(item.uom);
                this.itemsQuantityReq.push(0);
                this.itemsQuantityAdj.push(0);
                this.itemsPrice.push(0);
                this.itemsPriceCustom.push(0);
                this.itemsSubtotal.push(0);
                this.itemsDiscount.push(0);
                this.itemsTax.push(0);
                this.itemsLineTotal.push(0);
                
                this.itemsSubtotalEngine.push(0);
                this.itemsDiscountEngine.push(0);
                this.itemsTaxEngine.push(0);
                this.itemsLineTotalEngine.push(0);
            } else {
                this.$vs.notify({
                    title: "Warning",
                    text: "You're not allowed to add or remove external Sales Order item line ",
                    color: "warning",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check"
                });
            }
        },
        selectCustomer(customer) {
            this.calculated = false;
            this.updateSelectedPromotionCode([]);
            this.updateLimitItemByPromotion([]);
            if(this.external == false) {
                // hanya update value yg penting2 aja wkwk
                this.salesOrder.Customer.Code = customer.code;
                this.salesOrder.Customer.Name = customer.name;
                this.salesOrder.CustomerID = customer.id;

                this.getOptionBillTo(1, true);
                // this.salesOrder.BillToAddressID = null;
                // this.salesOrder.BillAddress.Address = null;
                // this.salesOrder.BillAddress.City = null;

                this.getOptionShipTo(1, true);
                // this.salesOrder.ShipmentToAddressID = null;
                // this.salesOrder.ShipAddress.Address = null;
                // this.salesOrder.ShipAddress.City = null;
                
                // this.getTime(this.salesOrder.CustomerID); // ini utk set default time based on custsomer work time
            } else {
                this.$vs.notify({
                    title: "Warning",
                    text: "You're not allowed to change external Sales Order Customer ",
                    color: "warning",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check"
                });
            }
        },
        selectBillTo(billTo) {
            if(this.external == false) {
                // hanya update value yg penting2 aja wkwk
                this.salesOrder.BillAddress.Address = billTo.address;
                this.salesOrder.BillAddress.City = billTo.city;
                this.salesOrder.BillToAddressID = billTo.id;
            } else {
                this.$vs.notify({
                    title: "Warning",
                    text: "You're not allowed to change external Sales Order Customer ",
                    color: "warning",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check"
                });
            }
        },
        selectShipTo(shipTo) {
            if(this.external == false) {
                // hanya update value yg penting2 aja wkwk
                this.salesOrder.ShipAddress.Address = shipTo.address;
                this.salesOrder.ShipAddress.City = shipTo.city;
                this.salesOrder.ShipmentToAddressID = shipTo.id;
            } else {
                this.$vs.notify({
                    title: "Warning",
                    text: "You're not allowed to change external Sales Order Customer ",
                    color: "warning",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check"
                });
            }
        },
        updateSelectedPromotionCode(selectedPromotionCode) {
            this.calculated = false;
            this.promotionCode = selectedPromotionCode;
            console.log("updateSelectedPromotionCode", this.promotionCode);
        },
        updateLimitItemByPromotion(limitedItem) {
            this.calculated = false;

            for (let index = 0; index < limitedItem.length; index++) {
                this.limitedItem[index] = limitedItem[index].item_unit_id;
            }
            console.log("updateLimitItemByPromotion", this.limitedItem);

            this.itemsCode = [];
            this.itemsName = [];
            this.itemsUom = [];
            this.itemsQuantityReq = [];
            this.itemsQuantityAdj = [];

            this.itemsPrice = [];
            this.itemsPriceCustom = [];
            this.itemsSubtotal = [];
            this.itemsDiscount = [];
            this.itemsTax = [];
            this.itemsLineTotal = [];
            
            this.itemsSubtotalEngine = [];
            this.itemsDiscountEngine = [];
            this.itemsTaxEngine = [];
            this.itemsLineTotalEngine = [];

            this.pricingStep = [];
            this.discountStep = [];
            this.totalDiscountStep = [];

            this.totalBrutto = 0;
            this.totalDiscount = 0;
            this.totalNetto = 0;
            this.totalTax = 0;
            this.totalBilling = 0;
        },
        updateSelectedReservationCode(selectedReservationCode) {
            this.calculated = false;
            this.salesOrder.ReservationCode = selectedReservationCode;
            console.log("updateSelectedReservationCode", this.salesOrder.ReservationCode);
        },
        deleteItemLine(index) {
            this.calculated = false;
            if(this.external == false) {
                this.itemsCode.splice(index, 1);
                this.itemsName.splice(index, 1);
                this.itemsUom.splice(index, 1);
                this.itemsQuantityReq.splice(index, 1);
                this.itemsQuantityAdj.splice(index, 1);
                
                this.itemsPrice.splice(index, 1);
                this.itemsPriceCustom.splice(index, 1);
                this.itemsSubtotal.splice(index, 1);
                this.itemsDiscount.splice(index, 1);
                this.itemsTax.splice(index, 1);
                this.itemsLineTotal.splice(index, 1);
                
                this.itemsSubtotalEngine.splice(index, 1);
                this.itemsDiscountEngine.splice(index, 1);
                this.itemsTaxEngine.splice(index, 1);
                this.itemsLineTotalEngine.splice(index, 1);

                this.pricingStep.splice(index, 1);
                this.discountStep.splice(index, 1);
                this.totalDiscountStep.splice(index, 1);
            } else {
                this.$vs.notify({
                    title: "Warning",
                    text: "You're not allowed to add or remove external Sales Order item line",
                    color: "warning",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check"
                });
            }
        },
        changeLimit(limit) {
            this.limitShow = limit;
            if(this.optionItemAvailableShow) {
                this.getOptionItemAvailable(this.isActive);
            }
            if(this.optionCustomerShow) {
                this.getOptionCustomer(this.isActive);
            }
            if(this.optionBillToShow) {
                this.getOptionBillTo(this.isActive);
            }
            if(this.optionShipToShow) {
                this.getOptionShipTo(this.isActive);
            }
        },
        changePrice() {
            this.calculated = false;
        },
        changeCharge() {
            this.calculated = false;
        },
        getOptionItemAvailable(page = 1) {
            this.isActive = page;
            this.$vs.loading();

            this.$http.get("/api/v1/item-available", {
                params: {
                    territory_code : this.selectTerritory.code,
                    search: this.search,
                    limit: this.limitShow,
                    offset: this.limitShow * (page - 1),
                    item_unit_id: this.limitedItem
                }
            }).then(resp => {
                // console.log(resp.data);
                if (resp.code == 200) {
                    this.optionItemAvailable = resp.data.ItemsAvailable;
                    this.recordsTotal = resp.data.RecordsTotal;
                    this.maxPage = Math.ceil(this.recordsTotal / this.limitShow);
                    this.dataEnd = resp.data.ResultsTotal;
                } else {
                    this.$vs.notify({
                        title: "Error",
                        text: "Failed to get Item Available",
                        color: "danger",
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-check"
                    });
                }
                this.$vs.loading.close();
            });
        },
        getOptionCustomer(page = 1) {
            this.isActive = page;
            this.$vs.loading();

            this.$http.get("/api/v1/master/customer", {
                params: {
                    search: this.search,
                    length: this.limitShow,
                    page: page,
                    type: "End Customer",
                    territory_id: this.territoryId,
                }
            }).then(resp => {
                // console.log("option customer", resp);
                if (resp.code == 200) {
                    this.optionCustomer = resp.data.records;
                    this.recordsTotal = resp.data.total_record;
                    this.maxPage = Math.ceil(this.recordsTotal / this.limitShow);
                    this.dataEnd = resp.data.total_record_search;
                } else {
                    this.$vs.notify({
                        title: "Error",
                        text: "Failed to get Customer",
                        color: "danger",
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-check"
                    });
                }
                this.$vs.loading.close();
            });
        },
        getOptionBillTo(page = 1, autoDefault = false) {
            this.isActive = page;
            this.$vs.loading();

            this.$http.get("/api/v1/master/customer-address/bill-to", {
                params: {
                    search: this.search,
                    length: this.limitShow,
                    page: page,
                    customer_id: this.salesOrder.CustomerID,
                    territory_id: this.territoryId,
                }
            }).then(resp => {
                // console.log("option customer", resp);
                if (resp.code == 200) {
                    this.optionBillTo = resp.data.records;
                    this.recordsTotal = resp.data.total_record;
                    this.maxPage = Math.ceil(this.recordsTotal / this.limitShow);
                    this.dataEnd = resp.data.total_record_search;

                    if(autoDefault == true && this.optionBillTo.length > 0) {
                        this.salesOrder.BillAddress.Address = this.optionBillTo[0].address;
                        this.salesOrder.BillAddress.City = this.optionBillTo[0].city;
                        this.salesOrder.BillToAddressID = this.optionBillTo[0].ID;
                    }

                } else {
                    this.$vs.notify({
                        title: "Error",
                        text: "Failed to get Bill To Address",
                        color: "danger",
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-check"
                    });
                }
                this.$vs.loading.close();
            });
        },
        getOptionShipTo(page = 1, autoDefault = false) {
            this.isActive = page;
            this.$vs.loading();

            this.$http.get("/api/v1/master/customer-address/ship-to", {
                params: {
                    search: this.search,
                    length: this.limitShow,
                    page: page,
                    customer_id: this.salesOrder.CustomerID,
                    territory_id: this.territoryId,
                }
            }).then(resp => {
                // console.log("option customer", resp);
                if (resp.code == 200) {
                    this.optionShipTo = resp.data.records;
                    this.recordsTotal = resp.data.total_record;
                    this.maxPage = Math.ceil(this.recordsTotal / this.limitShow);
                    this.dataEnd = resp.data.total_record_search;

                    if(autoDefault == true && this.optionShipTo.length > 0) {
                        this.salesOrder.ShipAddress.Address = this.optionShipTo[0].address;
                        this.salesOrder.ShipAddress.City = this.optionShipTo[0].city;
                        this.salesOrder.ShipmentToAddressID = this.optionShipTo[0].ID;
                    }
                } else {
                    this.$vs.notify({
                        title: "Error",
                        text: "Failed to get Ship To Address",
                        color: "danger",
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-check"
                    });
                }
                this.$vs.loading.close();
            });
        },
        getOptionTax(page = 1) {
            this.isActive = page;
            this.$vs.loading();

            this.$http.get("/api/v1/master/tax", {
                params: {
                    search: this.search,
                    length: this.limitShow,
                    page: page,
                }
            }).then(resp => {
                if (resp.code == 200) {
                    this.optionTax = resp.data.records;
                    this.recordsTotal = resp.data.total_record;
                    this.maxPage = Math.ceil(this.recordsTotal / this.limitShow);
                    this.dataEnd = resp.data.total_record_search;

                    for (let index = 0; index < this.optionTax.length; index++) {
                        if(this.optionTax[index].IsDefault == 1 && this.selectTax.Name == "") {
                            this.selectTax = {
                                Name: this.optionTax[index].Name,
                                Rate: this.optionTax[index].Rate
                            };
                            break;
                        }   
                    }
                } else {
                    this.$vs.notify({
                        title: "Error",
                        text: "Failed to get Tax option",
                        color: "danger",
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-check"
                    });
                }
                this.$vs.loading.close();
            });
        },
        getOptionPaymentTerm() {
            this.$vs.loading();

            this.$http.get("/api/v1/master/payment-term", {
                params: {
                    order: 'name',
                    sort: 'asc',
                }
            }).then(resp => {
                if (resp.code == 200) {
                    this.optionPaymentTerm = resp.data.records;
                    if(this.salesOrder.PaymentTermID == 0 || this.salesOrder.PaymentTermID == null) {
                        this.selectPaymentTerm = {
                            id: this.optionPaymentTerm[0].id,
                            name: this.optionPaymentTerm[0].name,
                            rate: this.optionPaymentTerm[0].rate
                        };
                    } else {
                        for (let index = 0; index < this.optionPaymentTerm.length; index++) {
                            if(this.optionPaymentTerm[index].id == this.salesOrder.PaymentTermID) {
                                this.selectPaymentTerm = {
                                    id: this.optionPaymentTerm[index].id,
                                    name: this.optionPaymentTerm[index].name,
                                    rate: this.optionPaymentTerm[index].rate
                                };
                                break;
                            }   
                        }
                    }
                } else {
                    this.$vs.notify({
                        title: "Error",
                        text: "Failed to get Payment Term option",
                        color: "danger",
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-check"
                    });
                }
                this.$vs.loading.close();
            });
        },
        getOptionDeliveryType() {
            this.$vs.loading();

            this.$http.get("/api/v1/master/delivery-type", {
                params: {
                    order: 'id',
                    sort: 'asc',
                }
            }).then(resp => {
                if (resp.code == 200) {
                    this.optionDeliveryType = resp.data.records;
                    if(this.salesOrder.DeliveryTypeID == 0 || this.salesOrder.DeliveryTypeID == null) {
                        this.selectDeliveryType = {
                            id: this.optionDeliveryType[0].id,
                            name: this.optionDeliveryType[0].name,
                            own_delivery: this.optionDeliveryType[0].own_delivery,
                            pickup: this.optionDeliveryType[0].pickup,
                            parcel_services: this.optionDeliveryType[0].parcel_services,
                            code: this.optionDeliveryType[0].code
                        };
                    } else {
                        for (let index = 0; index < this.optionDeliveryType.length; index++) {
                            if(this.optionDeliveryType[index].id == this.salesOrder.DeliveryTypeID) {
                                this.selectDeliveryType = {
                                    id: this.optionDeliveryType[index].id,
                                    name: this.optionDeliveryType[index].name,
                                    own_delivery: this.optionDeliveryType[index].own_delivery,
                                    pickup: this.optionDeliveryType[index].pickup,
                                    parcel_services: this.optionDeliveryType[index].parcel_services,
                                    code: this.optionDeliveryType[index].code
                                };
                                break;
                            }   
                        }
                    }
                } else {
                    this.$vs.notify({
                        title: "Error",
                        text: "Failed to get Delivery Type option",
                        color: "danger",
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-check"
                    });
                }
                this.$vs.loading.close();
            });
        },
        CalculateSOLine() {
            if(this.salesOrder.Customer.Code == "" || this.salesOrder.Customer.Code == 0 || this.salesOrder.Customer.Code == null ) {
                this.$vs.notify({
                    title: "Error",
                    text: "Please select Customer first",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check"
                });
                return true;
            }

            if(this.salesOrder.BillToAddressID == "" || this.salesOrder.BillToAddressID == 0 || this.salesOrder.BillToAddressID == null ) {
                this.$vs.notify({
                    title: "Error",
                    text: "Please select Billing Address first",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check"
                });
                return true;
            }

            if(this.salesOrder.ShipmentToAddressID == "" || this.salesOrder.ShipmentToAddressID == 0 || this.salesOrder.ShipmentToAddressID == null ) {
                this.$vs.notify({
                    title: "Error",
                    text: "Please select Shipping Address first",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check"
                });
                return true;
            }

            if(this.salesOrder.Date == "" || this.salesOrder.Date == 0 || this.salesOrder.Date == null) {
                this.$vs.notify({
                    title: "Error",
                    text: "Please select Date first",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check"
                });
                return true;
            }

            if(this.salesOrder.DueDate == "" || this.salesOrder.DueDate == 0 || this.salesOrder.DueDate == null ) {
                this.$vs.notify({
                    title: "Error",
                    text: "Please select Delivery Date first",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check"
                });
                return true;
            }

            if(!this.checkDate()) {
                this.$vs.notify({
                    title: "Error",
                    text: "Please select Delivery Date must be equal or after Sales Order Date",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check"
                });
                return true;
            }

            if(this.selectPaymentTerm.id == "" || this.selectPaymentTerm.id == 0 || this.selectPaymentTerm.id == null) {
                this.$vs.notify({
                    title: "Error",
                    text: "Please select Payment Term first",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check"
                });
                return true;
            }

            if(this.selectDeliveryType.id == "" || this.selectDeliveryType.id == 0 || this.selectDeliveryType.id == null) {
                this.$vs.notify({
                    title: "Error",
                    text: "Please select Payment Term first",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check"
                });
                return true;
            }

            this.search = "";
            this.indexStep = 0;
            this.optionItemAvailableShow = false;
            this.optionCustomerShow = false;
            this.optionBillToShow = false;
            this.optionShipToShow = false;
            this.detailStepShow = false;
            this.detailTotalDiscountStepShow = false;
            this.optionPromotionCodeShow = false;
            this.optionReservationCodeShow = false;
            // this.$vs.loading();
            this.$http
            .post("/api/v2/sales-order/calculate", {
                sales_channel_code: this.salesOrder.SalesChannel.Code,
                territory_code: this.territoryCode,
                company_code: this.salesOrder.Company.Code,
                customer_code: this.salesOrder.Customer.Code,
                promo_code: this.promotionCode,
                items_code: this.itemsCode,
                items_name: this.itemsName,
                items_uom: this.itemsUom,
                items_quantity_request: this.itemsQuantityReq,
                items_quantity_adjusted: this.itemsQuantityAdj,
                items_price: this.itemsPriceCustom,
                tax_rate: this.selectTax.Rate,
                subtotal_charge: parseFloat(this.salesOrder.SubtotalCharge),
            })
            .then(resp => {
                // console.log(resp);
                if (resp.code == 200) {
                    this.$vs.notify({
                        title: "Success",
                        text: "",
                        color: "success",
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-check"
                    });
                    // console.log(resp);
                    // this.itemsQtyStep = [];
                    // this.itemsPriceStep = [];
                    // this.itemsDiscountStep = [];

                    this.itemsPrice = [];
                    this.itemsPriceCustom = [];
                    this.itemsSubtotal = [];
                    this.itemsTax = [];
                    this.itemsLineTotal = [];
                    
                    this.itemsSubtotalEngine = [];
                    this.itemsTaxEngine = [];
                    this.itemsLineTotalEngine = [];

                    this.pricingStep = [];
                    this.discountStep = [];
                    this.totalDiscountStep = [];
                    
                    this.discountStepEngine = [];
                    this.totalDiscountStepEngine = [];

                    var sales_order = resp.data;
                    this.totalBrutto = this.formatCurrency(sales_order.total_order); // total order = harga satuan dikali jumlah 
                    this.totalDiscount = this.formatCurrency(sales_order.total_discount); // total diskon = totalnya diskon
                    this.totalNetto = this.formatCurrency(sales_order.total_netto); // total nett = total order - total diskon
                    this.totalTax = this.formatCurrency(sales_order.total_tax); // total pajak = total nett * pajak
                    this.totalBilling = this.formatCurrency(sales_order.total_billing); // total bayar = total nett + total pajak
                    this.salesOrder.SubtotalCharge = sales_order.total_charge
                    
                    this.priceProposal = sales_order.price_proposal;

                    this.calculated = true;
                    for (var i = 0; i < sales_order.pricing.length; i++) {
                        this.itemsPrice[i] = sales_order.pricing[i].price;
                        this.itemsSubtotal[i] = sales_order.pricing[i].sub_total;
                        this.itemsTax[i] = sales_order.pricing[i].tax;

                        this.itemsSubtotalEngine[i] = sales_order.pricing[i].sub_total_engine;
                        this.itemsTaxEngine[i] = sales_order.pricing[i].tax_engine;

                        if(sales_order.pricing[i].price <= 0) {
                            this.calculated = false;
                        }

                        if(sales_order.pricing[i].sub_total <= 0 && sales_order.pricing[i].sub_total_engine <= 0) {
                            this.calculated = false;
                        }
                        
                        // PRICING
                        if(sales_order.price_model == "Step Ladder" && sales_order.pricing[i].steps.length > 0) {
                            this.pricingStep[i] = sales_order.pricing[i].steps;
                        } else {
                            this.pricingStep = [];
                        }
                        this.itemsPriceCustom[i] = sales_order.pricing[i].custom_price;

                        // DISCOUNT
                        var lineTotal = 0;
                        var lineTotalEngine = 0;
                        if(i < sales_order.discount_line.length) {
                            if(sales_order.discount_model == "Step Ladder" && this.containsKey(sales_order.discount_line[i], 'steps') && sales_order.discount_line[i].steps.length > 0) {
                                this.discountStep[i] = sales_order.discount_line[i].steps;
                                this.discountStepEngine[i] = sales_order.discount_line_engine[i].steps;
                            } else {
                                this.discountStep = [];
                                this.discountStepEngine = [];
                            }
                            this.itemsDiscount[i] = sales_order.discount_line[i].amount;
                            this.itemsDiscountEngine[i] = sales_order.discount_line_engine[i].amount;

                            lineTotal = sales_order.pricing[i].sub_total - sales_order.discount_line[i].amount + sales_order.pricing[i].tax;
                            lineTotalEngine = sales_order.pricing[i].sub_total_engine - sales_order.discount_line_engine[i].amount + sales_order.pricing[i].tax_engine;
                        } else {
                            this.discountStep = [];
                            this.discountStepEngine = [];
                            this.itemsDiscount[i] = 0;
                            this.itemsDiscountEngine[i] = 0;
                            lineTotal = sales_order.pricing[i].sub_total - 0 + sales_order.pricing[i].tax;
                            lineTotalEngine = sales_order.pricing[i].sub_total_engine - 0 + sales_order.pricing[i].tax_engine;
                        }

                        this.itemsLineTotal[i] = lineTotal;
                        this.itemsLineTotalEngine[i] = lineTotalEngine;
                    }

                    // discount unit == final
                    if(sales_order.discount_unit == "Total" && sales_order.discount_model == "Step Ladder" && this.containsKey(sales_order.discount, 'steps') && sales_order.discount.steps.length > 0) {
                        this.totalDiscountStep = sales_order.discount.steps;
                        this.totalDiscountStepEngine = sales_order.discount_engine.steps;
                    }

                    this.PriceModel = sales_order.price_model;
                    this.DiscountModel = sales_order.discount_model;
                    this.DiscountUnit = sales_order.discount_unit;

                    if(sales_order.total_billing <= 0) {
                        this.calculated = false;
                    }
                } else {
                    this.$vs.notify({
                        title: "Error",
                        text: "Failed to calculate Sales Order",
                        color: "danger",
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-check"
                    });
                    this.calculated = false;
                }
                // this.$vs.loading.close();
            });
        },
        SubmitForm() {
            console.log("submit", this.salesOrder);
            if(this.salesOrder.BillToAddressID == "" || this.salesOrder.BillToAddressID == 0 || this.salesOrder.BillToAddressID == null ) {
                this.$vs.notify({
                    title: "Error",
                    text: "Please select Billing Address first",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check"
                });
                return true;
            }

            if(this.salesOrder.ShipmentToAddressID == "" || this.salesOrder.ShipmentToAddressID == 0 || this.salesOrder.ShipmentToAddressID == null ) {
                this.$vs.notify({
                    title: "Error",
                    text: "Please select Shipping Address first",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check"
                });
                return true;
            }

            if(this.salesOrder.Date == "" || this.salesOrder.Date == 0 || this.salesOrder.Date == null) {
                this.$vs.notify({
                    title: "Error",
                    text: "Please select Date first",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check"
                });
                return true;
            }

            if(this.salesOrder.DueDate == "" || this.salesOrder.DueDate == 0 || this.salesOrder.DueDate == null ) {
                this.$vs.notify({
                    title: "Error",
                    text: "Please select Delivery Date first",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check"
                });
                return true;
            } else {
                console.log("submit due date : ", this.salesOrder.DueDate);
            }

            if(!this.checkDate()) {
                this.$vs.notify({
                    title: "Error",
                    text: "Please select Delivery Date must be equal or after Sales Order Date",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check"
                });
                return true;
            }

            if(this.selectPaymentTerm.id == "" || this.selectPaymentTerm.id == 0 || this.selectPaymentTerm.id == null) {
                this.$vs.notify({
                    title: "Error",
                    text: "Please select Payment Term first",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check"
                });
                return true;
            }

            if(this.selectDeliveryType.id == "" || this.selectDeliveryType.id == 0 || this.selectDeliveryType.id == null) {
                this.$vs.notify({
                    title: "Error",
                    text: "Please select Payment Term first",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check"
                });
                return true;
            }

            this.$vs.loading();
            this.$http
            .post("/api/v2/sales-order/simple-store", {
                sales_order_code: this.salesOrder.Code,
                po_reference_code: this.salesOrder.PoReferenceCode,
                delivery_order_code: this.salesOrder.DeliveryOrderCode,
                sales_channel_code: this.salesOrder.SalesChannel.Code,
                territory_code: this.territoryCode,
                company_code: this.salesOrder.Company.Code,
                customer_code: this.salesOrder.Customer.Code,
                promo_code: this.promotionCode,
                items_code: this.itemsCode,
                items_name: this.itemsName,
                items_uom: this.itemsUom,
                items_quantity_request: this.itemsQuantityReq,
                items_quantity_adjusted: this.itemsQuantityAdj,
                items_price: this.itemsPriceCustom,
                bill_to_address_id: this.salesOrder.BillToAddressID,
                shipment_to_address_id: this.salesOrder.ShipmentToAddressID,
                due_date: this.salesOrder.DueDate,
                date: this.salesOrder.Date,
                tax_rate: this.selectTax.Rate,
                allow_partial: parseInt(this.salesOrder.AllowPartial),
                notes: this.salesOrder.Notes,
                payment_term_id: this.selectPaymentTerm.id,
                delivery_type_id: this.selectDeliveryType.id,
                delivery_type_code: this.selectDeliveryType.code,
                subtotal_charge: this.salesOrder.SubtotalCharge,
            })
            .then(resp => {
                // console.log(resp);
                if (resp.code == 200) {
                    this.$vs.notify({
                        title: "Success",
                        text: "Sales Order has been added",
                        color: "success",
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-check"
                    });
                    this.CancelForm();
                    this.setDefaultDate();
                    this.getOptionTax();
                } else {
                    this.$vs.notify({
                        title: "Error",
                        text: resp.message,
                        color: "danger",
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-check"
                    });
                }
                this.$vs.loading.close();
            });
        },
        setDefaultDate() {
            var day = new Date();
            var nextDay = new Date(day);
            nextDay.setDate(day.getDate() + 1); 

            this.salesOrder.Date = day
            this.salesOrder.DueDate = nextDay
        },
        getTime(id) {
            this.$vs.loading();
            this.$http.get("/api/v1/master/customer/work-time/"+id).then(resp => {
                if (resp.code == 200) {
                   var day = new Date();
                    var nextDay = new Date(day);
                    nextDay.setDate(day.getDate() + 1);
                    nextDay.setHours(resp.data[0])
                    nextDay.setMinutes(resp.data[1])
                    this.salesOrder.DueDate = nextDay
                }
                this.$vs.loading.close();
            });
        },
    },
};
</script>
