<template>
    <div>
        <!-- main page -->
        <vs-tabs ref="sub-tabs" alignment="center">
            <!-- <vs-tab label="New SO">
                <div class="con-tab-ejemplo">
                    <datatable :progress="''" :progressRestriction="false" :territory="territory.code" :salesChannel="salesChannel.code" :customerCategory="customerCategory.ID" v-on:clickEdit="clickEdit"></datatable>
                </div>
            </vs-tab> -->
            <vs-tab label="Validated">
                <div class="con-tab-ejemplo">
                    <datatable :progress="'validated'" :progressRestriction="true" :territory="territory.code" :salesChannel="salesChannel.code" :customerCategory="customerCategory.ID"></datatable>
                </div>
            </vs-tab>
            <vs-tab label="Approved">
                <div class="con-tab-ejemplo">
                    <datatable :progress="'approved'" :progressRestriction="true" :territory="territory.code" :salesChannel="salesChannel.code" :customerCategory="customerCategory.ID"></datatable>
                </div>
            </vs-tab>
            <vs-tab label="Rejected">
                <div class="con-tab-ejemplo">
                    <datatable :progress="'rejected'" :progressRestriction="true" :territory="territory.code" :salesChannel="salesChannel.code" :customerCategory="customerCategory.ID" v-on:clickEdit="clickEdit"></datatable>
                </div>
            </vs-tab>
            <vs-tab label="Canceled">
                <div class="con-tab-ejemplo">
                    <datatable :isCancel="1" :territory="territory.code" :salesChannel="salesChannel.code" :customerCategory="customerCategory.ID"></datatable>
                </div>
            </vs-tab>
            <vs-tab label="Released">
                <div class="con-tab-ejemplo">
                    <datatable :progress="'released'" :progressRestriction="true" :territory="territory.code" :salesChannel="salesChannel.code" :customerCategory="customerCategory.ID"></datatable>
                </div>
            </vs-tab>
            <vs-tab label="Allocated">
                <div class="con-tab-ejemplo">
                    <datatable :progress="'allocated'" :progressRestriction="true" :territory="territory.code" :salesChannel="salesChannel.code" :customerCategory="customerCategory.ID"></datatable>
                </div>
            </vs-tab>
            <vs-tab label="|" disabled></vs-tab>
            <vs-tab label="Delivered">
                <div class="con-tab-ejemplo">
                    <datatable :progressWarehouse="'handovering'" :progressRestriction="true" :territory="territory.code" :salesChannel="salesChannel.code" :customerCategory="customerCategory.ID"></datatable>
                </div>
            </vs-tab>
            <vs-tab label="Bill">
                <div class="con-tab-ejemplo">
                    <datatable :isBilled="1" :territory="territory.code" :salesChannel="salesChannel.code" :customerCategory="customerCategory.ID"></datatable>
                </div>
            </vs-tab>
            <vs-tab label="Closed">
                <div class="con-tab-ejemplo">
                    <datatable :isClosed="1" :territory="territory.code" :salesChannel="salesChannel.code" :customerCategory="customerCategory.ID"></datatable>
                </div>
            </vs-tab>
            <!-- <vs-tab label="Planning">
                <div class="con-tab-ejemplo">
                    <datatable :progressWarehouse="'planning'" :progressRestriction="true" :territory="territory.code" :salesChannel="salesChannel.code" :customerCategory="customerCategory.ID"></datatable>
                </div>
            </vs-tab>
            <vs-tab label="Picking">
                <div class="con-tab-ejemplo">
                    <datatable :progressWarehouse="'picking'" :progressRestriction="true" :territory="territory.code" :salesChannel="salesChannel.code" :customerCategory="customerCategory.ID"></datatable>
                </div>
            </vs-tab>
            <vs-tab label="Packing">
                <div class="con-tab-ejemplo">
                    <datatable :progressWarehouse="'packing'" :progressRestriction="true" :territory="territory.code" :salesChannel="salesChannel.code" :customerCategory="customerCategory.ID"></datatable>
                </div>
            </vs-tab>
            -->
        </vs-tabs>
    </div>
</template>


<script>
// import detail from "../component/detail_selected_so.vue";
import datatable from "./datatable.vue";

export default {
    components: {
        // detail,
        datatable
    },
    props: 
        ["territory", "salesChannel", "customerCategory"],
        data() {
            return {
                // 
            };
        },
    watch: {
        // 
    },
    mounted() {
        // 
    },
    computed: {
        // 
    },
    methods: {
        scrollToTop() {
            window.scrollTo(0,0);
        },

        clickEdit(salesOrder) {
            this.$emit("selectSalesOrder", salesOrder);
            this.$emit("redirectTab", 1);
        },
    }
};
</script>

<style>
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}
</style>