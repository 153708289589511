<template>
    <div class="vx-col md:w-1/1 w-full">
        <vx-card title="Sales Order" style="z-index:10">
            <div class="vx-row mb-6" style="width:50%">
                <vs-button class="ml-4 mt-2" color="danger" icon-pack="feather" icon="icon-arrow-left" @click="$router.go(-1)">Back</vs-button>
            </div>
            <div class="vx-row mb-6" style="width:50%">
                <div class="vx-col sm:w-1/3 w-full flex items-center">
                    <span>Territory</span>
                </div>
                <div class="vx-col sm:w-2/3 w-full">
                    <multiselect
                        class="selectExample"
                        v-model="territory"
                        :options="optionTerritory"
                        :multiple="false"
                        :allow-empty="false"
                        :group-select="false"
                        :max-height="120"
                        :limit="3"
                        placeholder="Type to search"
                        track-by="code"
                        label="name"
                    >

                        <template slot="singleLabel" slot-scope="props">
                            <span class="option__desc">
                                <span class="option__title">({{ props.option.code }}) {{ props.option.name }}</span>
                            </span>
                        </template>

                        <template slot="option" slot-scope="props">
                            <div class="option__desc">
                                <span class="option__title">({{ props.option.code }}) {{ props.option.name }}</span>
                            </div>
                        </template>

                    </multiselect>
                </div>
            </div>
            <div class="vx-row mb-6" style="width:50%">
                <div class="vx-col sm:w-1/3 w-full flex items-center">
                    <span>Sales Channel</span>
                </div>
                <div class="vx-col sm:w-2/3 w-full">
                    <multiselect
                        class="selectExample"
                        v-model="salesChannel"
                        :options="optionSalesChannel"
                        :multiple="false"
                        :allow-empty="false"
                        :group-select="false"
                        :max-height="120"
                        :limit="3"
                        placeholder="Type to search"
                        track-by="code"
                        label="name"
                    >

                        <template slot="singleLabel" slot-scope="props">
                            <span class="option__desc">
                                <span class="option__title">({{ props.option.code }}) {{ props.option.name }}</span>
                            </span>
                        </template>

                        <template slot="option" slot-scope="props">
                            <div class="option__desc">
                                <span class="option__title">({{ props.option.code }}) {{ props.option.name }}</span>
                            </div>
                        </template>

                    </multiselect>
                </div>
            </div>
            <div class="vx-row mb-6" style="width:50%">
                <div class="vx-col sm:w-1/3 w-full flex items-center">
                    <span>Distribution Channel</span>
                </div>
                <div class="vx-col sm:w-2/3 w-full">
                    <multiselect
                        class="selectExample"
                        v-model="customerCategory"
                        :options="optionCustomerCategory"
                        :multiple="false"
                        :allow-empty="false"
                        :group-select="false"
                        :max-height="120"
                        :limit="3"
                        placeholder="Type to search"
                        track-by="ID"
                        label="name"
                    >

                        <template slot="singleLabel" slot-scope="props">
                            <span class="option__desc">
                                <span class="option__title">{{ props.option.name }}</span>
                            </span>
                        </template>

                        <template slot="option" slot-scope="props">
                            <div class="option__desc">
                                <span class="option__title">{{ props.option.name }}</span>
                            </div>
                        </template>

                    </multiselect>
                </div>
            </div>
        </vx-card>
        <vx-card title="" style="z-index:9">
            <vs-tabs :color="colorx" ref="tabs">
                <vs-tab @click="changeTab(0)" label="New SO">
                    <div class="con-tab-ejemplo">
                        <br>
                        <newSO :territory="territory.code" :salesChannel="salesChannel.code" :customerCategory="customerCategory.ID"  v-on:selectSalesOrder="updateSelectedSalesOrder" v-on:redirectTab="redirectTab"></newSO>
                    </div>
                </vs-tab>
                <vs-tab @click="changeTab(1)" label="Form">
                    <div class="con-tab-ejemplo">
                        <br>
                        <formSO :territoryCode="territory.code" :territoryId="territory.id" :salesChannel="salesChannel.code" :customerCategory="customerCategory.ID" :selectedSO="selectedSO" :optionTerritory="optionTerritory" v-on:selectSalesOrder="updateSelectedSalesOrder"></formSO>
                    </div>
                </vs-tab>
                <vs-tab @click="changeTab(2)" label="Validation">
                    <div class="con-tab-ejemplo">
                        <br>
                        <validationSO :territory="territory.code" :salesChannel="salesChannel.code" :customerCategory="customerCategory.ID"></validationSO>
                    </div>
                </vs-tab>
                <vs-tab @click="changeTab(3)" label="Release">
                    <div class="con-tab-ejemplo">
                        <br>
                        <releaseSO :territory="territory.code" :salesChannel="salesChannel.code" :customerCategory="customerCategory.ID"></releaseSO>
                    </div>
                </vs-tab>
                <vs-tab @click="changeTab(4)" label="Partial Allocation">
                    <div class="con-tab-ejemplo">
                        <br>
                        <partialAllocation :territory="territory.code" :salesChannel="salesChannel.code" :customerCategory="customerCategory.ID"  v-on:selectSalesOrder="updateSelectedSalesOrder" v-on:redirectTab="redirectTab"></partialAllocation>
                    </div>
                </vs-tab>
                <!-- <vs-tab @click="changeTab(5)" label="Rejected SO">
                    <div class="con-tab-ejemplo">
                        <br>
                        <rejectedSObyManager :territory="territory.code" :salesChannel="salesChannel.code" :customerCategory="customerCategory.ID"  v-on:selectSalesOrder="updateSelectedSalesOrder" v-on:redirectTab="redirectTab"></rejectedSObyManager>
                    </div>
                </vs-tab> -->
                <vs-tab @click="changeTab(5)" label="SO Tracker">
                    <div class="con-tab-ejemplo">
                        <br>
                        <monitoring :territory="territory" :salesChannel="salesChannel" :customerCategory="customerCategory"  v-on:selectSalesOrder="updateSelectedSalesOrder" v-on:redirectTab="redirectTab"></monitoring>
                    </div>
                </vs-tab>
            </vs-tabs>
        </vx-card>
    </div>
</template>

<script>
import newSO from "./new_so/datatable.vue";
import validationSO from "./validation/datatable.vue";
import formSO from "./form/form.vue";
import releaseSO from "./release_so/datatable.vue";
// import rejectedSObyManager from "./rejected_so/datatable.vue";
import partialAllocation from "./partial_allocation/datatable.vue";
import monitoring from "./monitoring/index.vue";

export default {
    components: {
        newSO,
        validationSO,
        formSO,
        releaseSO,
        // rejectedSObyManager,
        partialAllocation,
        monitoring
    },
    mounted() {
        this.getOptionTerritory();
        this.getOptionSalesChannel();
        this.getOptionCustomerCategory();
        // console.log("mounted", this.selectedSO);
    },
    data: () => ({
        tabColor:['success', 'primary', 'warning', 'info', 'success', 'rgb(62, 201, 214)'],
        tabIndex: 0,
        territory: {},
        optionTerritory: [{}],
        salesChannel: {},
        optionSalesChannel:[{}],
        customerCategory: {},
        optionCustomerCategory:[{}],
        colorx: "success",
        selectedSO: {}
    }),
    watch: {
        // territory: function(newValue, oldValue) {
        //     // console.log(newValue, oldValue);
        // }
        customerCategory: function(newValue, oldValue) {
            console.log("customerCategory", newValue, oldValue);
        }
    },
    methods: {
        changeTab(index) {
            // console.log("before", this.tabIndex);
            // console.log("after", index);
            this.tabIndex = index;
            this.colorx = this.tabColor[index];
        },
        getOptionTerritory() {
            this.$http.get("/api/v1/master/multiple-territory").then(resp => {
                this.optionTerritory = resp.data.records;
                this.territory = resp.data.records[0];
            });
        },
        getOptionSalesChannel() {
            this.$http.get("/api/v1/sales-channel/personal").then(
                resp => {
                    // console.log(resp)
                    if(resp.code == 200){
                        this.optionSalesChannel = []
                        this.optionSalesChannel.push({code:'All'})
                        for(var salesChannel = 0;salesChannel<resp.data.length;salesChannel++){
                            this.optionSalesChannel.push(resp.data[salesChannel])
                        }
                        this.salesChannel = this.optionSalesChannel[0];
                        // this.territory = resp.data.territory[0];
                    }else{
                        // this.$vs.loading.close();
                        this.$router.push("/");
                    }
                }
            );
        },
        getOptionCustomerCategory() {
            this.$http.get("/api/v1/master/customer-category-all").then(
                resp => {
                    if(resp.code == 200){
                        this.optionCustomerCategory = []
                        this.optionCustomerCategory.push({name:'All'})
                        for(var customerCategory = 0;customerCategory<resp.data.length;customerCategory++){
                            this.optionCustomerCategory.push(resp.data[customerCategory])
                        }
                        this.customerCategory = this.optionCustomerCategory[0];
                    }else{
                        // this.$vs.loading.close();
                        this.$router.push("/");
                    }
                }
            );
        },
        updateSelectedSalesOrder(salesOrder) {
            this.selectedSO = salesOrder;
            this.changeTab(1);
            // console.log("selected", this.selectedSO);
        },
        redirectTab(index) {
            this.$refs.tabs.activeChild(index);
        }
    }
};
</script>

<style scoped>
.vs-collapse-item--content {
  height: 100%;
  max-height: 14444px;
}
</style>